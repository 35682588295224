export const services = [
    {
        name: "90 minutes consultation",
        price: "120",
        description: "A standard counseling session that is usually enough to cover important life issues in some depth, with or without astrological details. Transits and progressions can be covered if time allows."
    },
    {
        name: "120 minutes consultation",
        price: "150",
        description: "If you would like a deeper astrological focus, including aspects, transits, progressions, or questions about astrological technique, this extended session should be enough to meet all your needs."
    },
    {
        name: "60 minutes consultation",
        price: "90",
        description: "A shorter session for returning clients or in case you only want an overview of your chart."
    },
    {
        name: "Complementary birth chart report",
        price: "30",
        description: "If you are having a consultation with me, you can order a complementary written birth chart report based on the findings of the consultation. The report will be at most two pages long. Note that whether you want a written report or not, you will always be offered a video recording of the consultation."
    },
    // {
    //     name: "Complete birth chart analysis",
    //     price: "90",
    //     description: "If you do not want to meet face to face, you can order a complete birth chart analysis in writing, around 3 pages in length. Note that this is not my usual approach and I cannot guarantee that it will be completely accurate!"
    // },
]